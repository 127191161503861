import {Context} from "@profiscience/knockout-contrib-router";
import {campaignApi, commentApi, contentApi, ideaApi, notificationApi, userApi} from "../../api/api-wrapper";
import {CampaignDto, CommentDto, IdeaDto, UserDto, WidgetDto} from "../../api/generated";
import '../../components/elements/news/news-list-item';
import {autobind, computed, observable, observableArray} from "knockout-decorators";
import {saveWidget} from "../editor/widget-utils";
import * as ko from "knockout";
import {isActive} from "../campaigns/campaignUtils";
import globalState from "../../global-state"
import {WidgetContentNews} from "../editor/_common";
import '../../components/elements/campaigns/active-campaign-item';
import '../../components/elements/news/pinned-news-item';
import {config} from "../../utils/clientConfigWrapper";


class ViewModelContext extends Context {
    campaigns: CampaignDto[];
    newestIdeas: IdeaDto[];
    highlightedIdeas: IdeaDto[];
    comments: CommentDto[];
    adminContacts: UserDto[];
    newsWidgets: WidgetDto[];
    mainWidget: WidgetDto;
    sidebarWidget: WidgetDto;
}

class ViewModel {

    /**
     * The carousel items.
     *
     * Might be:
     * the active campaigns - or if not available
     * the pinned news - or if not available
     * the latest news
     */
    public carouselItems: Array<CampaignDto | WidgetDto>;

    /**
     * The campaigns.
     */
    public campaigns: Array<CampaignDto>;

    /**
     * The newest ideas.
     */
    public newestIdeas: Array<IdeaDto>;

    /**
     * The featured ideas.
     */
    public highlightedIdeas: Array<IdeaDto>;

    /**
     * The latest comments.
     */
    @observableArray({deep: false, expose: false})
    public comments: CommentDto[];

    public moreComments: KnockoutObservable<boolean>;

    /**
     * The admin contact user.
     */
    public adminContacts: UserDto[];

    /**
     * Latest news.
     */
    public newsWidgets: WidgetDto[];

    /**
     * Widget area in the main column.
     */
    public mainWidget: WidgetDto;

    /**
     * Flag whether the tutorial should be dismissed permanently or visible after next login.
     */
    public tutorialDismissed: KnockoutObservable<boolean>;

    /**
     * Widget area in the sidebar.
     */
    @observable({deep: true})
    public sidebarWidget: WidgetDto;

    public mainEditing = ko.observable(false);
    public sidebarEditing = ko.observable(false);

    constructor(ctx: ViewModelContext) {
        this.campaigns = this.filterCampaigns(ctx.campaigns);
        this.newestIdeas = ctx.newestIdeas;
        this.highlightedIdeas = ctx.highlightedIdeas;
        this.comments = ctx.comments;
        this.adminContacts = ctx.adminContacts;
        this.newsWidgets = ctx.newsWidgets;
        this.mainWidget = ctx.mainWidget;
        this.sidebarWidget = ctx.sidebarWidget;
        this.carouselItems = this.findCarouselItems(); // must be initialized after campaigns and news
        this.tutorialDismissed = ko.observable(globalState.user().tutorialDismissed === true);
        this.moreComments = ko.observable(this.comments.length > 0);
    }

    /**
     * Find the carousel items.
     *
     * Might be:
     * the active campaigns - or if not available
     * the pinned news - or if not available
     * the latest news
     */
    @autobind
    private findCarouselItems(): Array<CampaignDto | WidgetDto> {
        const result: Array<CampaignDto | WidgetDto> = [];
        if (this.campaigns.length > 0) {
            result.push(...this.campaigns);
        }
        const pinnedNews: WidgetDto[] = this.newsWidgets.filter(widget => (<WidgetContentNews>widget.content).pinned);
        if (pinnedNews.length > 0) {
            result.push(...pinnedNews);
        } else if (this.newsWidgets.length > 0) {
            result.push(...this.newsWidgets.slice(0, 1));
        }
        return result.sort((item1, item2) => {
            const date1 = this.widgetType(item1) == WidgetDto.TypeEnum.CAMPAIGN ?
                (item1 as CampaignDto).start : (item1 as WidgetDto).content.publishDate;
            const date2 = this.widgetType(item2) == WidgetDto.TypeEnum.CAMPAIGN ?
                (item2 as CampaignDto).start : (item2 as WidgetDto).content.publishDate;
            return (<string><any>date2).localeCompare(<string><any>date1);
        });
    }

    /**
     * Get the widget type for rendering the right component in del carousel.
     *
     * @param widget
     */
    public widgetType(widget: CampaignDto | WidgetDto) {
        if (widget.hasOwnProperty('type')) {
            // In our case NEWS
            return (<WidgetDto>widget).type;
        }
        // its a CampaignDto
        return WidgetDto.TypeEnum.CAMPAIGN;
    }

    /**
     * Filter the campaigns.
     *  - campaign must be active
     *
     * @param campaigns
     */
    private filterCampaigns(campaigns: CampaignDto[]): CampaignDto[] {
        return campaigns
            .filter(campaign => isActive(campaign));
    }

    @autobind
    public saveMainWidget() {
        return saveWidget(this.mainWidget).then(() => this.mainEditing(false));
    }

    @autobind
    public saveSidebarWidget() {
        return saveWidget(this.sidebarWidget).then(() => this.sidebarEditing(false));
    }

    @computed
    public get tutorialEnabled() {
        return globalState.tutorialEnabled;
    }

    @autobind
    public dismissTutorial() {
        const user = globalState.user();
        user.tutorialDismissed = this.tutorialDismissed();
        userApi.putUserTutorialDismissed("" + this.tutorialDismissed());
        globalState.tutorialEnabled(false);
    }

    @computed
    public get tutorialCarouselInterval() {
        return config.tutorialCarouselInterval;
    }

    @autobind
    public reloadComments() {
        const appVars = globalState.appVars || {};
        const limit = this.comments.length + (appVars.homeListsLimit || 9);

        commentApi.getComments(limit).then(comments => {
           this.comments = comments;
           this.moreComments(limit == comments.length);
        });
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./home.html'),
    componentName: "home",
    loader: (ctx: ViewModelContext) => {
        const appVars = globalState.appVars || {};
        return Promise
            .all([
                campaignApi.getCampaigns().then(campaigns =>
                    ctx.campaigns = campaigns),
                ideaApi.getIdeas(null, "newest", appVars.homeListsLimit || 9)
                    .then(ideas => ctx.newestIdeas = ideas),
                ideaApi.getIdeas(null, "highlighted", appVars.homeListsLimit || 9)
                    .then(ideas => ctx.highlightedIdeas = ideas),
                commentApi.getComments(appVars.homeListsLimit || 9)
                    .then(comments => ctx.comments = comments),
                userApi.getAdminUsers().then(users => ctx.adminContacts = users),
                contentApi.getWidgets('NEWS', appVars.homeListsLimit || 9)
                    .then(newsWidgets => ctx.newsWidgets = newsWidgets),
                contentApi.getWidget(config.widgetIds.homeMain)
                    .then(mainWidget => ctx.mainWidget = mainWidget),
                contentApi.getWidget(config.widgetIds.homeSidebar)
                    .then(sidebarWidget => ctx.sidebarWidget = sidebarWidget),
                notificationApi.getUserNotifications().then(notifications =>
                    globalState.notifications = notifications)
            ])
    }
};

import {Context, Router} from "@profiscience/knockout-contrib-router";
import {Evaluation, EvaluationDto, UserDto} from "../../api/generated";
import {evaluationApi, userApi} from "../../api/api-wrapper";
import {autobind, observable, unwrap} from "knockout-decorators";
import {ideaAttachmentsRegex} from "../ideas/ideaUtils";
import globalState from "../../global-state"
import {postbox} from "../../components/util/postbox";
import * as ko from "knockout";

import {bonus, evaluationDeadline} from "./evaluationUtils";
import {FileUploadViewModel} from "../../utils/FileUploadViewModel";
import "../../components/elements/idea/evaluation-idea"
import ImplementEnum = Evaluation.ImplementEnum;


class ViewModelContext extends Context {
    evaluation: EvaluationDto;
    adminContacts: UserDto[];
}

class ViewModel extends FileUploadViewModel {

    /**
     * The evaluation.
     */
    @observable({deep: true, expose: true})
    public evaluation: EvaluationDto;

    /**
     * Implementation date
     */
    public implementationDateForm: KnockoutObservable<{implementationDate: KnockoutObservable<string>}>;

    /**
     * The admin contact user.
     */
    @observable({deep: false, expose: false})
    public adminContacts: UserDto[];

    /**
     * The bonus according to the score of all criterias
     */
    public bonus: KnockoutComputed<number>;

    /**
     * Constructor.
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.implementationDateForm = ko.validatedObservable({
            implementationDate: ko.observable(ctx.evaluation.implementationDate).extend({ required: true })
        });
        this.evaluation = Object.assign({}, this.createEvaluation(), ctx.evaluation);
        this.adminContacts = ctx.adminContacts;
        this.bonus = bonus(this.evaluation);

        unwrap(this.evaluation, "implementationDescription").extend({required: true, maxlength: 65535});

        console.debug("Add evaluation implemetation description: ", this.evaluation);
    }

    /**
     * Create an evaluation with all the properties which can be edited in this view.
     */
    private createEvaluation() {
        return {
            description: null,
            implement: null,
            implementationDate: null,
            implementationDescription: null,
            score: null,
            bonus: null,
            submitted: false,
            revise: false,
            evaluated: false,
            postponeUntil: null,
            postponeReason: null,
            active: false,
            criteriaList: [],
            implementationApproved: false
        }
    }

    /**
     *
     */
    @autobind
    public implementationDateVisible() {
        return this.evaluation.implement === ImplementEnum.Implement
    }

    /**
     * Get the evaluation deadline.
     */
    @autobind
    public evaluationDeadline(): KnockoutComputed<Date> {
        return evaluationDeadline(this.evaluation);
    }

    @autobind
    public saveImplementationDate() {
        const errors = ko.validation.group(this.implementationDateForm());
        if (errors().length > 0) {
            errors.showAllMessages();
            postbox.addError('validation.failed');
        } else {
            globalState.loading(true);
            this.evaluation.implementationDate = this.implementationDateForm().implementationDate();
            return evaluationApi.putEvaluation(this.evaluation.id, this.evaluation, globalState.sendMail()).then(value => {
                globalState.loading(false);
                postbox.addInfo("evaluation.success.saveImplementationDate");
                return Router.update(`/idee/${this.evaluation.idea.id}`, {
                    push: true,
                    force: false
                });
            }).catch(reason => {
                globalState.loading(false);
                postbox.addError("evaluation.error.saveImplementationDate");
            }).finally(() =>
                globalState.loading(false));
        }
    }

    /**
     * Save the evaluation.
     */
    @autobind
    save() {
        console.debug("saving evaluation", this.evaluation);
        globalState.loading(true);
        return this.filesPromise(ideaAttachmentsRegex)
            .then(uploads =>
                this.evaluation.implementationUploads = uploads)
            .then(value =>
                evaluationApi.putEvaluation(this.evaluation.id, this.evaluation, globalState.sendMail()).then(value => {
                    globalState.loading(false);
                    postbox.addInfo("evaluation.success.saveImplementationDescription");
                    return Router.update(`/idee/${this.evaluation.idea.id}`, {
                        push: true,
                        force: false
                    });
                }).catch(reason => {
                    globalState.loading(false);
                    postbox.addError("evaluation.error.saveImplementationDescription");
                })
            )
            .catch(err =>
                console.error(err))
            .finally(() =>
                globalState.loading(false));
    }

    /**
     * Submit the evaluation.
     * Sets the evaluated flag to true and saves the evaluation.
     */
    @autobind
    submit() {
        const errors = ko.validation.group(this.evaluation);
        if (errors().length > 0) {
            console.error("errors", errors);
            errors.showAllMessages();
            postbox.addError('validation.failed');
        } else {
            this.save();
        }
    }

    /**
     * Cancel the evaluation
     */
    @autobind
    cancel() {
        return Router.update(`/profile/${globalState.user().id}`, {
            push: true,
            force: false
        });
    }


}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./implementation.html'),
    componentName: "implementation",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Umsetzungsbericht ${ctx.params && ctx.params.id || ''}`;
        return Promise.all([
            evaluationApi.getEvaluation(ctx.params.id).then(evaluation =>
                ctx.evaluation = evaluation
            ),
            userApi.getAdminUsers().then(users =>
                ctx.adminContacts = users
            )
        ]);
    }
};

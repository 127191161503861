import {Context, Router} from "@profiscience/knockout-contrib-router";
import {EvaluationAssessmentCriteriaDto, EvaluationDto, UserDto} from "../../api/generated";
import {evaluationApi, userApi} from "../../api/api-wrapper";
import {autobind, observable} from "knockout-decorators";
import {ideaAttachmentsRegex} from "../ideas/ideaUtils";
import {bonus} from "./evaluationUtils";
import globalState from "../../global-state"
import {postbox} from "../../components/util/postbox";
import * as ko from "knockout";
import {FileUploadViewModel} from "../../utils/FileUploadViewModel";
import "../../components/elements/idea/evaluation-idea"


class ViewModelContext extends Context {
    evaluation: EvaluationDto;
    criteriaTypes: string[];
    adminContacts: UserDto[];
}

class ViewModel extends FileUploadViewModel {

    /**
     * The evaluation.
     */
    public evaluation: EvaluationDto;

    /**
     * The admin description or the expert users description.
     */
    public evaluationDescription: KnockoutComputed<string>;

    /**
     * The evaluation criterias.
     */
    @observable({ deep: true, expose: true})
    public assessmentCriterias: EvaluationAssessmentCriteriaDto[];

    /**
     * Assessment comment
     */
    public comment: KnockoutObservable<string>;

    /**
     * The admin contact user.
     */
    public adminContacts: UserDto[];

    /**
     * The bonus according to the score of all criterias
     */
    public bonus: KnockoutComputed<number>;


    /**
     * Constructor.
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.evaluation = ctx.evaluation;
        this.adminContacts = ctx.adminContacts;
        this.comment = ko.observable(this.evaluation.assessmentComment || "");
        this.bonus = bonus(this.evaluation);
        this.assessmentCriterias = this.evaluation.assessmentCriteriaList && this.evaluation.assessmentCriteriaList.length > 0 ?
            this.evaluation.assessmentCriteriaList : this.createAssessmentCriterias(ctx.criteriaTypes);
        this.evaluationDescription = ko.pureComputed(() => {
            if(this.evaluation.adminDescription && this.evaluation.adminDescription.trim().length > 0) {
                return this.evaluation.implementationDescription;
            }
            return  this.evaluation.description;
        });

        console.debug("Edit evaluation: ", this.assessmentCriterias);
    }

    /**
     * Create an evaluation with all the properties which can be edited in this view.
     */
    private createAssessmentCriterias(criteriaTypes: unknown[]): EvaluationAssessmentCriteriaDto[] {
        return criteriaTypes.map(criteriaType => {
            return {
                score: 1,
                timestamp: new Date(),
                type: <EvaluationAssessmentCriteriaDto.TypeEnum>criteriaType
            }
        });
    }

    /**
     * Save the evaluation.
     */
    @autobind
    save() {
        console.debug("saving evaluation", this.evaluation);
        this.evaluation.assessmentCriteriaList = this.assessmentCriterias;
        this.evaluation.assessmentComment = this.comment();
        globalState.loading(true);
        return evaluationApi.putEvaluation(this.evaluation.id, this.evaluation).then(value => {
                globalState.loading(false);
                postbox.addInfo("evaluation.assessmentCriteria.success.save");
                return Router.update(`/idee/${this.evaluation.idea.id}`, {
                    push: true,
                    force: false
                });
            }).catch(reason => {
                globalState.loading(false);
                postbox.addError("evaluation.assessmentCriteria.error.save");
            })
            .finally(() =>
                globalState.loading(false));
    }

    /**
     * Submit the evaluation.
     * Sets the evaluated flag to true and saves the evaluation.
     */
    @autobind
    submit() {
        const errors = ko.validation.group(this.evaluation);
        if (errors().length > 0) {
            console.error("errors", errors);
            errors.showAllMessages();
            postbox.addError('validation.failed');
        } else {
            this.evaluation.assessed = true;
            this.evaluation.submitted = true;
            this.save();
        }
    }

    /**
     * Cancel the evaluation
     */
    @autobind
    cancel() {
        return Router.update(`/idee/${this.evaluation.idea.id}`, {
            push: true,
            force: false
        });
    }


}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./assessment.html'),
    componentName: "assessment",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Beurteilung Bewertung ${ctx.params && ctx.params.id || ''}`;
        return Promise.all([
            evaluationApi.getEvaluation(ctx.params.id).then((evaluation) => ctx.evaluation = evaluation),
            evaluationApi.getEvaluationAssessmentCriteriaTypes().then(types => ctx.criteriaTypes = types),
            userApi.getAdminUsers().then(users => ctx.adminContacts = users)
        ]);
    }
};

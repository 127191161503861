import * as ko from "knockout";
import {Evaluation, EvaluationDto, IdeaDto} from "../../api/generated";
import moment = require("moment");
import ImplementEnum = Evaluation.ImplementEnum;
import globalState from '../../global-state';


/**
 * The bonus according to the score of all criteria.
 * @param evaluation
 */
export function bonus(evaluation: EvaluationDto): KnockoutComputed<number> {
    return ko.pureComputed(() => {
        const score = evaluation.criteriaList
            .map(criteria => criteria.score ? criteria.score : 1)
            .reduce((previousValue, currentValue) => previousValue + currentValue);
        return globalState.appVars.scoreBonus[score.toString()] || 0;
    });
}

/**
 * Get the css class for an evaluation status.
 * @param evaluation
 */
export function evaluationStatusClass(evaluation: EvaluationDto): KnockoutComputed<string> {
    return ko.pureComputed(() => {
        return "bl-state--" + evaluationStatusColor(evaluation)();
    });
}

/**
 * Get the color code for an evaluation status.
 * @param evaluation
 */
export function evaluationStatusColor(evaluation: EvaluationDto): KnockoutComputed<string> {
    return ko.pureComputed(() => {
        const now = moment();

        // IDW-260 grün wenn Ideestatus 'wurde umgesetzt', 'wird nicht umgesetzt' oder 'Idee nicht neu'
        if(evaluation.idea.state == IdeaDto.StateEnum.Implemented ||
            evaluation.idea.state == IdeaDto.StateEnum.Canceled  ||
            evaluation.idea.state == IdeaDto.StateEnum.NotNew) {
            return "green";
        }

        // Status für Umsetzungsbericht erstellen
        if(evaluation.implement && evaluation.implement == ImplementEnum.Implement && evaluation.submitted  &&
            (!evaluation.implementationDescription || evaluation.implementationDescription.trim().length == 0)) {
            const descriptionDeadline = implementationDescriptionDeadline(evaluation)();
            if(now.isAfter(descriptionDeadline)) {
                return "red";
            } else if (now.isAfter(descriptionDeadline.subtract(14, "days"))) {
                return "yellow";
            }
            return "green";
        }

        // Status für Evaluierung
        if(evaluation.submitted || evaluation.evaluated ) {
            return "green"
        }
        if (evaluation.timestamp === undefined || evaluation.timestamp === null) {
            return "unknown";
        }
        const deadline: Date = evaluationDeadline(evaluation)();
        if (now.isAfter(moment(deadline))) {
            // after the deadline
            return "red";
        } else if (now.isAfter(moment(deadline))) {
            // less than 3 weeks before the deadline
            return "yellow";
        }
        // more than 3 weeks before the deadline.
        return "green";
    });
}

/**
 * The deadline for the implementation description.
 * Either the postponeUntil date or the implementationDate.
 */
export function implementationDescriptionDeadline(evaluation: EvaluationDto): KnockoutComputed<moment.Moment> {
    return ko.pureComputed(() => {
        const postponeDate = evaluation.postponeUntil && evaluation.postponeUntil.trim().length > 0 ?
            parseDateString(evaluation.postponeUntil) : undefined;
        const implementationDate = evaluation.implementationDate && evaluation.implementationDate.trim().length > 0 ?
            parseDateString(evaluation.implementationDate) : undefined;

        // if both dates are set, use the later one
        if (postponeDate && implementationDate) {
            return postponeDate.isAfter(implementationDate, 'day') ? postponeDate : implementationDate;

        } else if (postponeDate) {
            return postponeDate;

        } else if (implementationDate) {
            return implementationDate;

        } else if (evaluation.timestamp && evaluation.timestamp != null) {
            // Bug fix for existing data which do not have a postponeUntil or implementationDate
            return moment(evaluation.timestamp);
        }
        // Bug fix for existing data which do not have a timestamp
        return parseDateString(evaluation.created);
    });
}

/**
 * Check if the implementation description is missing. It's missing if:
 * - evaluation is submitted
 * - decision is 'Implement'
 * - implementation date is set and after today
 * - and there is no implementation description
 *
 * @param evaluation
 */
export function isImplementationDescriptionMissing(evaluation: EvaluationDto): boolean {
    if (evaluation.submittedTimestamp && evaluation.implement == EvaluationDto.ImplementEnum.Implement
        && evaluation.implementationDate && evaluation.implementationDate.trim().length > 0 &&
        (!evaluation.implementationDescription || evaluation.implementationDescription.trim().length == 0)) {
        const implementationDate = parseDateString(evaluation.implementationDate);
        return implementationDate.isBefore(moment());
    }
    return false;
}

/**
 * Check if the assessment is missing.
 * It is missing if:
 * - evaluation is submitted
 * - evaluation is active
 * - evaluation is evaluated (published by admin)
 * - evaluation is not assessed
 *
 * @param evaluation
 */
export function isAssessmentMissing(evaluation: EvaluationDto): boolean {
    return evaluation.submittedTimestamp && evaluation.active && evaluation.evaluated
        && !evaluation.assessed;
}

/**
 * The evaluation deadline
 * either the postponeUntil date
 * or the evaluation created date plus 6 weeks.
 *
 * @param evaluation
 */
export function evaluationDeadline(evaluation: EvaluationDto): KnockoutComputed<Date> {
    return ko.pureComputed(() => {
        const deadline = moment(evaluation.created)
            .add(globalState.appVars.evaluationDeadline || 21, 'days').toDate();
        if(!evaluation.postponeAccepted) {
            return deadline;
        }
        return parseDateString(evaluation.postponeUntil) != null ?
            parseDateString(evaluation.postponeUntil).toDate() : deadline;
    });
}


function parseDateString(date: string): moment.Moment {
    if(date === undefined || date === null) {
        return null;
    }
    return moment(date, 'YYYY-MM-DD');
}

/**
 * Calculate the average score and round to two decimals if necessary.
 *
 * @param scoreTotal
 * @param numberOfItems
 */
export function averageScore(scoreTotal:number, numberOfItems: number ): number {
    return numberOfItems > 0 ? Math.round((scoreTotal / numberOfItems) * 100) / 100 : 0;
}

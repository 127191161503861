import * as ko from 'knockout'
import {autobind, observable, unwrap} from "knockout-decorators";
import {EvaluationCriteriaDto} from "../../api/generated";
import ensureObservable from "../../components/util/ensure-observable";

interface ViewModelParams {
    criteria: EvaluationCriteriaDto;
    editable: boolean | KnockoutObservable<boolean>;
}


class ViewModel {

    /**
     * The criteria.
     */
    @observable({deep: true, expose: true})
    public criteria: EvaluationCriteriaDto;

    /**
     * Comment visible state
     */
    public commentVisible: KnockoutObservable<boolean>;

    /**
     * Flag whether the criteria is editable
     */
    public editable: KnockoutObservable<boolean>;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params: ViewModelParams) {
        this.criteria = Object.assign(params.criteria, { comment: params.criteria.comment ? params.criteria.comment : null });
        this.commentVisible = ko.observable((this.criteria.comment && this.criteria.comment.length > 0) ? true : false);
        this.editable = params.editable === undefined ? ko.observable(false) : ensureObservable(params.editable);

        unwrap(this.criteria, "score").extend({required: false, number: true, min: 1, max: 4});
        unwrap(this.criteria, "comment").extend({required: false, maxLength: 65535});
    }

    /**
     * Toggle comment visible state.
     */
    @autobind
    public toggleCommentVisible() {
        this.commentVisible(!this.commentVisible());
    }

}


const component: KnockoutComponentTypes.Config = {
    viewModel: (params: ViewModelParams) => new ViewModel(params),
    template: <string>require('./criteria.html')
};

export default component;

if (!ko.components.isRegistered('criteria')) {
    ko.components.register('criteria', component)
}